import React from 'react';
import IndustryDataTypeTemplate from '@components/IndustryDataType/IndustryDataTypeTemplate';
import { dataTypes, industries } from '@constants/industriesAndDatatypes';
import BackgroundImage from './assets/Location_Cover.jpg';
import metaImage from './assets/meta.jpg';

const LocationPage: React.FC = () => {
  return (
    <IndustryDataTypeTemplate
      pageName="data-type-location"
      helmet={{
        title: 'TileDB - Model any location data & analyze efficiently at scale',
        description:
          'Use TileDB to power up geospatial applications, enabling analysis-ready cloud datasets without lengthy conversion pipelines and ETL.',
        shareImage: {
          url: metaImage,
          width: 1200,
          height: 627,
        },
      }}
      header="Location data"
      title="Rapidly slice any point within space and time"
      heroImage={BackgroundImage}
      icon={dataTypes.location.icon}
      overview={
        <>
          <p>
            For too long, geospatial analysts have been limited by location data managed by relational databases and data warehouses, which
            come with high maintenance and TCO.
          </p>
          <p>
            Take your analysis from cities and states to the entire globe using TileDB. With faster performance and lower infrastructure
            costs than solutions like PostGIS, TileDB’s cloud-native approach slices data rapidly, directly from inexpensive cloud object
            storage.
          </p>
          <p>
            The flexibility of multi-dimensional TileDB arrays addresses a range of location data types. Points in space are inherently
            sparse 2D arrays, but modern analyses add elevation and time as indexing requirements, calling for 4D arrays. TileDB natively
            accommodates this sparse 4D structure, which is ideal for geospatial analytics. Take advantage of lossless compression to reduce
            your storage spending. Supercharge your geospatial analytics and visualizations with TileDB’s serverless slicing, UDFs and SQL
            computations, as well as integrations with popular geospatial libraries and data science tooling.
          </p>
        </>
      }
      relatedItems={[
        industries.maritime,
        industries.telecom,
        industries.defense,
        industries.earthObservation,
        industries.oilAndGas,
        dataTypes.ais,
      ]}
    />
  );
};

export default LocationPage;
